<template>
    <div id="calculate-shipping">
        <div class="mb-6 px-2 py-3 border border-gray-400 rounded bg-gray-100">

            <template v-if="displayDeliveryQuoteForm">
                <h2 class="text-2xl mb-3 text-blue-700 text-center">
                    Estimate Shipping/Delivery
                </h2>
                
                <form id="shipping-quote" method="POST" @submit.prevent="sendRequest" class="mb-4">

                    <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">
                    </show-form-validation-errors>

                    <div style="display: none">
                        <input id="desired-shipping-date" 
                            type="text" 
                            name="desired_shipping_date"
                            v-model="desired_shipping_date"
                            class="appearance-none w-full text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:border-gray-500" 
                            placeholder="Desired Shipping Date" 
                            autocomplete="off" />
                    </div>

                    <input id="shipping-address" 
                        type="text" 
                        v-model="shipping_address" 
                        class="appearance-none block w-full text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:border-gray-500" 
                        placeholder="Address" 
                        required />
                    
                    <input id="shipping-city" 
                        type="text" 
                        v-model="shipping_city" 
                        class="appearance-none block w-full text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:border-gray-500" 
                        placeholder="City" 
                        required />

                    <select id="shipping-state" 
                        v-model="shipping_state" 
                        class="appearance-none block w-full border border-gray-400 rounded py-3 px-4 mb-3 leading-tight text-gray-700 focus:outline-none focus:bg-white focus:border-gray-500"  
                        required
                    >
                        <option value="">
                            Choose State
                        </option>

                        <option v-for="(all_state, index) in all_states" :value="all_state.abbr" :key="index">
                            {{ all_state.name }}
                        </option>
                    </select>

                    <input id="shipping-zip" 
                        type="text" 
                        v-model="shipping_zip" 
                        v-mask="'#####'" 
                        class="appearance-none block w-full text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:border-gray-500" 
                        placeholder="Zip Code" 
                        required />

                    <input type="hidden" 
                        name="cart_id" 
                        v-model="shoppingCartSummary.cart_id" />

                    <input type="hidden" 
                        name="quote_action" 
                        v-model="quote_action" />

                    <div v-if="displayFakeButton" class="flex justify-center">
                        <span class="px-4 py-2 mb-2 rounded border border-gray-400 cursor-not-allowed">
                            Fill Out The Form
                        </span>
                    </div>

                    <button 
                        type="submit" 
                        class="block mx-auto px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300" 
                        v-if="displayRealButton">
                        Get a Quote
                    </button>
                </form>
            </template>


            <template v-if="displaySpinner">
                <h2 class="text-2xl mb-4 text-blue-700 text-center">
                    Getting Quote ...
                </h2>
                <div class="mb-4 text-center">

                    <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                        c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="2s" 
                                from="0 50 50"
                                to="360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                        <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                        c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="1s" 
                                from="0 50 50"
                                to="360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                        <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                        L82,35.7z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="1.5s" 
                                from="0 50 50"
                                to="-360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                <p class="mb-4 text-center">
                    Please wait.
                </p>
            </template>


            <template v-if="displayQuote">
                <h2 class="mb-4 text-2xl text-blue-700 text-center">
                    Shipping/Delivery Quote
                </h2>

                <div class="flex justify-center content-center mb-6 text-lg">
                    <div class="text-gray-500 italic">
                        To:
                    </div>

                    <div class="mx-4">
                        {{ this.shippingQuote.quote_address }} <br>
                        {{ this.shippingQuote.quote_city }}, {{ this.shippingQuote.quote_state }} {{ this.shippingQuote.quote_zip }}
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved != 'success'" v-on:click="updateShippingAddress">
                        <svg class="h-8 w-8 mt-1 fill-current text-green-700 hover:text-green-400  hover:cursor-pointer" 
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <rect width="512" height="512" rx="76.8"/>
                            <path d="M415.08,100.44C387.61,73,367,77.07,367,77.07L251.71,192.34l-129,129-19.24,90.7,90.72-19.24,129-129L438.47,148.57S442.58,127.94,415.08,100.44ZM188.78,381.92l-30.93,6.66a70.64,70.64,0,0,0-13.14-17.75A71.28,71.28,0,0,0,127,357.67l6.66-30.91,8.95-8.94s16.82.35,35.83,19.35S197.75,373,197.75,373Z" style="fill:#fff"/>
                        </svg>
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved == 'success'">
                        <svg class="h-8 w-8 mt-1 fill-current text-gray-400 hover:cursor-not-allowed"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <rect width="512" height="512" rx="76.8"/>
                            <path d="M415.08,100.44C387.61,73,367,77.07,367,77.07L251.71,192.34l-129,129-19.24,90.7,90.72-19.24,129-129L438.47,148.57S442.58,127.94,415.08,100.44ZM188.78,381.92l-30.93,6.66a70.64,70.64,0,0,0-13.14-17.75A71.28,71.28,0,0,0,127,357.67l6.66-30.91,8.95-8.94s16.82.35,35.83,19.35S197.75,373,197.75,373Z" style="fill:#fff"/>
                        </svg>
                    </div>
                </div>

                
                <div v-for="quote in quotes" :key="quote.id" class="mb-4">
                    <div class="border-b border-blue-500 text-xl text-blue-600">
                        <h4 class="text-center">
                            {{ quote.header }}
                        </h4>
                    </div>

                    <div v-if="quote.error == 0">
                        <div class="mb-8 border-b border-blue-500">
                            <div class="flex justify-between py-2 border-b border-gray-400">
                                <p>{{ quote.lable }}</p>
                                <div class="text-right text-lg font-semibold">
                                    <template v-if="quote.cost == 'FREE'">
                                        <label class="mr-2 text-lg" :for="quote.lable">
                                            {{ quote.cost }}
                                        </label>
                                    </template>
                                    <template v-else>
                                        <label class="mr-2 text-lg" :for="quote.lable">
                                            ${{ quote.cost }}
                                        </label>
                                    </template>
                                    <input 
                                        class="h-4 w-4" type="radio" 
                                        :id="quote.number" name="childQuoteID" 
                                        :value="quote.id" v-model="childQuoteID" 
                                        v-on:change="updateState" 
                                        :disabled="checkoutProcess.synchronyApproved == 'success'"
                                    >
                                </div>
                            </div>
                            
                            <p class="py-2 text-sm text-center">
                                {{ quote.description }}
                            </p>
                        </div>
                    </div>

                    <div v-if="quote.error != 0">
                        <div class="mb-8 py-4 border-b border-blue-500 text-center">
                            <span class="text-red-500 mr-2">Warning! </span> {{ quote.error }}
                        </div>
                    </div>
                </div>
                
                
            </template>

            <div class="mb-4 text-center text-red-700">
                Shipping quotes are honored if shipped within 7 days.
            </div>

        </div>

    <spinner></spinner>

    </div>

</template>


<script>

import PleaseWaitSpinner from './modals/PleaseWaitSpinner.vue';
import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

    export default {

        components: {
            'spinner': PleaseWaitSpinner,
            'show-form-validation-errors': ShowFormValidationErrors
        },

        props: [
            'states',
            'freight_quote',
            'quote_details',
                // 'selected_quote', // ['', 1, 2, 3]
        ],

        computed: {

            shoppingCartSummary: {
                get() {
                    return this.$store.state.cartDetails.cartSummary;
                }
            },

            userInfo: {
                get() {
                    return this.$store.state.cartUserBillingShipping.userBillingShipping;
                }
            },

            checkoutProcess: {
                get() {
                    return this.$store.state.cartCheckoutProcess.checkoutSteps;
                }
            },

            shippingQuote: {
                get() {
                    return this.$store.state.cartShipping.shippingQuote;
                }
            }
        },

        data: function () {
            return {
                // form data
                desired_shipping_date: '',
                shipping_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_zip: '',
                quote_action: 'first',  // quote event message logic

                // form logic
                address: false,
                city: false,
                state: false,
                zip: false,
                validationErrors: '',
                

                        // // Quote // ???
                        // quote_price: 'TBD', // for form to be send out

                display_quote_price: 'TBD', // for local component only, it could contain prefix ($)
                        // quote_label: 'Shipping/Delivery',
                        // quote_carrier: '',
                        // quote_number: '',
                        // qoute_address: '',
                        // quote_city: '',
                        // quote_state: '',
                        // quote_zip: '',
                        // quote_description: '',
                        // local_delivery_area: '',
                        // deliveryLabel: 'Shipping/Delivery',  // ???

                // Elements Display Logic
                displayRealButton: false,
                displayFakeButton: true,
                displaySpinner: false,
                displayDeliveryQuoteForm: true,
                displayQuote: false,

                // quote
                quotes: this.freight_quote,
                selectedQuoteID: '',
                childQuoteID: '',

                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                all_states: this.states,
            }
        },

        mounted: function () {

            this.$store.dispatch('cartShipping/initializeShippingQuote', this.quote_details);

            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                // check if we have quote from controller
                this.updateThisComponent();
            })
        },
        
        watch: {

            shipping_address: function(val, oldVal)
            {
                if (this.shipping_address.length != 0)
                {
                    this.address = true;
                    this.checkoutForm();

                } else {

                    this.address = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            shipping_city: function(val, oldVal)
            {
                if (this.shipping_city.length != 0)
                {
                    this.city = true;
                    this.checkoutForm();

                } else {

                    this.city = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            shipping_state: function(val, oldVal)
            {
                if (this.shipping_state.length != 0)
                {
                    this.state = true;
                    this.checkoutForm();

                } else {

                    this.state = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            shipping_zip: function(val, oldVal)
            {
                if (this.shipping_zip.length == 5)
                {
                    this.zip = true;
                    this.checkoutForm();

                } else{

                    this.zip = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            checkoutProcess: {
                handler(val) {

                    this.checkRecalculateShipping();
                },

                deep: true
            },
        },

        methods:{

            sendRequest ()
            {
                var that = this;
                this.displayDeliveryQuoteForm = false;
                this.displaySpinner    = true;
                this.displayQuote      = false;
                this.selectedQuoteID   = '';
                this.validationErrors = '';
                this.showSpinnerModal();

                axios.post('/get-shipping-quote', {
                    shipping_date: this.desired_shipping_date,
                    address:       this.shipping_address,
                    city:          this.shipping_city,
                    state:         this.shipping_state,
                    zip:           this.shipping_zip,
                    subtotal:      this.shoppingCartSummary.subtotal,
                    cart_id:       this.shoppingCartSummary.cart_id,
                    _token:        this.csrf,
                    quote_action:  this.quote_action,   // for cart event message 
                })

                .then(this.onSuccess)

                .catch(this.ifAxiosFails);

            },

            onSuccess (response)
            {
                this.quotes = response.data;
                this.hideSpinnerModal();
                this.displayQuote = true;
                this.selectedQuoteID   = '';
                this.display_quote_price = 'TBD';
                this.quote_label = 'Shipping/Delivery:';
                this.displayDeliveryQuoteForm = false;
                this.displaySpinner = false;
                // this.local_delivery_area = '';     // there is no reference
                
                this.shippingQuote.quote_address = this.quotes[1]['address'];
                this.shippingQuote.quote_city    = this.quotes[1]['city'];
                this.shippingQuote.quote_state   = this.quotes[1]['state'];
                this.shippingQuote.quote_zip     = this.quotes[1]['zip'];

                // Update User Shipping Info
                this.userInfo.shipping_address = this.quotes[1]['address'];
                this.userInfo.shipping_city    = this.quotes[1]['city'];
                this.userInfo.shipping_state   = this.quotes[1]['state'];
                this.userInfo.shipping_zip     = this.quotes[1]['zip'];
                // update Store
                this.$store.dispatch('cartUserBillingShipping/updateUserBillingShipping', this.userInfo);

                this.checkoutProcess.recalculateShipping = 'no';
                this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

                // Out of Local Delivery Area
                if (this.quotes[1]['local_delivery_area'] == "no")
                {
                    this.childQuoteID = 1;

                    // this.updateState();
                    this.refreshFreightQuote();
                }

                // Local Delivery Area
                if (this.quotes[1]['local_delivery_area'] == 'yes')
                {

                    if (this.shippingQuote.selected_quote != '')
                    {
                        this.refreshQuote();

                    } else {

                        this.childQuoteID    = '';
                        this.selectedQuoteID = '';
                        // The quote is not selected
                        this.refreshUnselectedQuote();
                    }
                }

            },


            ifAxiosFails (error)
            {
                this.hideSpinnerModal();
                this.displaySpinner = false;
                this.displayDeliveryQuoteForm = true;
                this.displayQuote  = false;

                if (error.response.status == 418) {
                    window.location.href = '/';
                }

                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            },


            checkRecalculateShipping()
            {
                if (this.checkoutProcess.recalculateShipping == 'yes')
                {
                    this.recalculateShippingQuote();
                }
            },

            recalculateShippingQuote()
            {
                var that = this;
                this.quote_action = 'recalculate';
                this.displayDeliveryQuoteForm = false;
                this.displaySpinner    = true;
                this.displayQuote      = false;
                this.selectedQuoteID   = '';
                // this.showSpinnerModal();
                axios.post('/get-shipping-quote', {
                    address:      this.userInfo.shipping_address,
                    city:         this.userInfo.shipping_city,
                    state:        this.userInfo.shipping_state,
                    zip:          this.userInfo.shipping_zip,
                    subtotal:     this.shoppingCartSummary.subtotal,
                    cart_id:      this.shoppingCartSummary.cart_id,
                    quote_action: this.quote_action,   // for cart event message  
                    _token:       this.csrf,
                })

                .then(this.onSuccess)

                .catch(this.ifAxiosFails);
            },

            checkoutForm: function()
            {
                if(this.address == true && this.city == true && this.state == true && this.zip == true)
                {
                    this.displayFakeButton = false;
                    this.displayRealButton = true;
                    this.displayDeliveryQuoteForm = true;
                    // this.displaySpinner    = false;
                    this.displayQuote      = false;

                } else {

                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                    this.displayDeliveryQuoteForm = true;
                    // this.displaySpinner    = false;
                    this.displayQuote      = false;
                }
            },

            updateState: function()  // when user select from local delivery options
            {
                this.selectedQuoteID = this.childQuoteID;

                if (this.quotes[this.selectedQuoteID]['cost'] == 'FREE')
                {
                    this.display_quote_price = this.quotes[this.selectedQuoteID]['cost'];
                    // this.estimatedTotal = this.subtotal;

                } else {

                    this.display_quote_price = '$' + this.quotes[this.selectedQuoteID]['cost'];
                    // this.calculateTotal();
                }

                // Rest of Quote Variables
                this.shippingQuote.display_quote_price = this.display_quote_price;
                this.shippingQuote.quote_price         = this.quotes[this.selectedQuoteID]['cost'];
                this.shippingQuote.quote_label         = this.quotes[this.selectedQuoteID]['label'];
                this.shippingQuote.quote_company       = this.quotes[this.selectedQuoteID]['quote_company'];
                this.shippingQuote.quote_carrier       = this.quotes[this.selectedQuoteID]['carrier'];
                this.shippingQuote.quote_number        = this.quotes[this.selectedQuoteID]['number'];
                this.shippingQuote.quote_address       = this.quotes[this.selectedQuoteID]['address'];
                this.shippingQuote.quote_city          = this.quotes[this.selectedQuoteID]['city'];
                this.shippingQuote.quote_state         = this.quotes[this.selectedQuoteID]['state'];
                this.shippingQuote.quote_zip           = this.quotes[this.selectedQuoteID]['zip'];
                this.shippingQuote.quote_description   = this.quotes[this.selectedQuoteID]['description'];
                this.shippingQuote.tax_rate            = this.quotes[this.selectedQuoteID]['tax_rate'];
                this.shippingQuote.local_delivery_area = this.quotes[this.selectedQuoteID]['local_delivery_area']; // ['yes', 'no']
                this.shippingQuote.selected_quote      = this.selectedQuoteID;
                this.shippingQuote.quote_count         = 3;
                // update store
                this.$store.dispatch('cartShipping/updateShippingQuote', this.shippingQuote);

                // update store (cartCheckoutProcess)
                this.checkoutProcess.quoteCompleted = 'yes';
                this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

                // save to the DB
                this.updateCheckoutProcess();

                // Send axios request to update DB variables
                // this.saveSelectedQuote();  
                // I want to save quote event once
                // See function below

                // Send axios request to update DB variables
                this.saveSelectedQuoteAndQuoteEvent();
            },

            updateThisComponent: function()
            {
                if (this.shippingQuote.quote_count > 1 && this.shippingQuote.selected_quote != '')
                {
                    // We have quotes and selected_quote
                    this.refreshQuote();

                } else if (this.shippingQuote.quote_count == 1) {

                    // It is Freight Quote (only one option)
                    this.refreshFreightQuote();

                } else if (this.shippingQuote.quote_count > 1 && this.shippingQuote.selected_quote == '') {

                    // We have quotes, nothing is selected yet.
                    this.refreshUnselectedQuote();

                } else {

                    // 
                }
            },

            refreshQuote: function()
            {
                
                this.selectedQuoteID = this.shippingQuote.selected_quote;


                if (this.quotes[this.selectedQuoteID]['cost'] == 'FREE')
                {
                    this.display_quote_price = this.quotes[this.selectedQuoteID]['cost'];

                } else {

                    this.display_quote_price = '$' + this.quotes[this.selectedQuoteID]['cost'];
                }

                // Rest of Quote Variables
                this.shippingQuote.display_quote_price = this.display_quote_price;
                this.shippingQuote.quote_price         = this.quotes[this.selectedQuoteID]['cost'];
                this.shippingQuote.quote_label         = this.quotes[this.selectedQuoteID]['label'];
                this.shippingQuote.quote_company       = this.quotes[this.selectedQuoteID]['quote_company'];
                this.shippingQuote.quote_carrier       = this.quotes[this.selectedQuoteID]['carrier'];
                this.shippingQuote.quote_number        = this.quotes[this.selectedQuoteID]['number'];
                this.shippingQuote.quote_address       = this.quotes[this.selectedQuoteID]['address'];
                this.shippingQuote.quote_city          = this.quotes[this.selectedQuoteID]['city'];
                this.shippingQuote.quote_state         = this.quotes[this.selectedQuoteID]['state'];
                this.shippingQuote.quote_zip           = this.quotes[this.selectedQuoteID]['zip'];
                this.shippingQuote.quote_description   = this.quotes[this.selectedQuoteID]['description'];
                this.shippingQuote.tax_rate            = this.quotes[this.selectedQuoteID]['tax_rate'];
                this.shippingQuote.local_delivery_area = this.quotes[this.selectedQuoteID]['local_delivery_area']; // ['yes', 'no']
                // update store
                this.$store.dispatch('cartShipping/updateShippingQuote', this.shippingQuote);

                // Send Axios request to update DB
                this.saveSelectedQuote();

                // update store (cartCheckoutProcess)
                this.checkoutProcess.quoteCompleted      = 'yes';
                this.checkoutProcess.recalculateShipping = 'no';
                this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);
                // save to the DB
                this.updateCheckoutProcess();

                // update visual components
                this.displayQuote             = true;
                this.displayDeliveryQuoteForm = false;
                // this.displaySpinner           = false;
                this.childQuoteID             = this.selectedQuoteID;

            },

            refreshFreightQuote: function()
            {
                // Assign selectedQuoteID regardless initial selected_quote
                // Freight Quote has only one option
                this.selectedQuoteID = 1;

                if (this.quotes[this.selectedQuoteID]['cost'] == 'TBD')
                {
                    this.display_quote_price = this.quotes[this.selectedQuoteID]['cost'];

                } else {

                    this.display_quote_price = '$' + this.quotes[this.selectedQuoteID]['cost'];
                }
                
                // Rest of Quote Variables
                this.shippingQuote.quote_price         = this.quotes[this.selectedQuoteID]['cost'];
                this.shippingQuote.display_quote_price = this.display_quote_price;
                this.shippingQuote.quote_company       = this.quotes[this.selectedQuoteID]['quote_company'];
                this.shippingQuote.quote_label         = this.quotes[this.selectedQuoteID]['label'];
                this.shippingQuote.quote_company       = this.quotes[this.selectedQuoteID]['quote_company'];
                this.shippingQuote.quote_carrier       = this.quotes[this.selectedQuoteID]['carrier'];
                this.shippingQuote.quote_number        = this.quotes[this.selectedQuoteID]['number'];
                this.shippingQuote.quote_address       = this.quotes[this.selectedQuoteID]['address'];
                this.shippingQuote.quote_city          = this.quotes[this.selectedQuoteID]['city'];
                this.shippingQuote.quote_state         = this.quotes[this.selectedQuoteID]['state'];
                this.shippingQuote.quote_zip           = this.quotes[this.selectedQuoteID]['zip'];
                this.shippingQuote.quote_description   = this.quotes[this.selectedQuoteID]['description'];
                this.shippingQuote.tax_rate            = this.quotes[this.selectedQuoteID]['tax_rate'];
                this.shippingQuote.local_delivery_area = this.quotes[this.selectedQuoteID]['local_delivery_area']; // ['yes', 'no']
                this.shippingQuote.selected_quote      = this.selectedQuoteID;
                this.shippingQuote.quote_count         = 1;
                // update store
                this.$store.dispatch('cartShipping/updateShippingQuote', this.shippingQuote);

                // Send Axios request to update DB
                // ??? Freight quote has only one option
                // this.saveSelectedQuote();  

                // update store (cartCheckoutProcess)
                this.checkoutProcess.quoteCompleted = 'yes';
                this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);
                // save to the DB
                this.updateCheckoutProcess();

                // update visual components
                this.displayQuote             = true;
                this.displayDeliveryQuoteForm = false;
                this.displaySpinner           = false;
                this.childQuoteID             = 1;
            },

            refreshUnselectedQuote: function()
            {
                // The Scenario
                // quote_count == 3 && selected_quote == '';
                
                // update visual components
                this.displayQuote             = true;
                this.displayDeliveryQuoteForm = false;
                // this.displaySpinner           = false;

                this.shippingQuote.quote_address   = this.quotes[1]['address'];
                this.shippingQuote.quote_city      = this.quotes[1]['city'];
                this.shippingQuote.quote_state     = this.quotes[1]['state'];
                this.shippingQuote.quote_zip       = this.quotes[1]['zip'];
                this.shippingQuote.tax_rate        = this.quotes[1]['tax_rate'];
                this.shippingQuote.selected_quote  = '';
                this.shippingQuote.quote_count     = 3;

                this.shippingQuote.local_delivery_area = this.quotes[1]['local_delivery_area']; // ['yes', 'no']

                // update store
                this.$store.dispatch('cartShipping/updateShippingQuote', this.shippingQuote);

                // Send Axios request to update DB
                this.saveSelectedQuote();

            },

            updateShippingAddress: function()
            {   
                // When user click on "Pencil" button
                // Reset Form
                // this.quotes = [];
                this.displayQuote = false;
                // this.selectedQuoteID   = '';
                this.display_quote_price = 'TBD';
                this.quote_label         = 'Shipping/Delivery:';
                this.quote_action        = 'change';             // quote event message
                this.displayDeliveryQuoteForm = true;
                // this.displaySpinner = false;
                // this.local_delivery_area = 'no';   // there is no reference

                // // Rest of Quote Variables
                // this.shippingQuote.display_quote_price = 'TBD';
                // this.shippingQuote.quote_price         = 'TBD';
                // this.shippingQuote.quote_label         = 'Shipping/Delivery:';
                // this.shippingQuote.quote_carrier       = '';
                // this.shippingQuote.quote_number        = '';
                // this.shippingQuote.quote_address       = '';
                // this.shippingQuote.quote_city          = '';
                // this.shippingQuote.quote_state         = '';
                // this.shippingQuote.quote_zip           = '';
                // this.shippingQuote.quote_description   = '';
                // this.shippingQuote.tax_rate            = 'TBD';
                // this.shippingQuote.local_delivery_area = 'no'; // ['yes', 'no']
                // // update store
                // this.$store.dispatch('cartShipping/updateShippingQuote', this.shippingQuote);

                // // Send Axios request to update DB
                // this.saveSelectedQuote();

            },

            saveSelectedQuote: function()
            {
                // Send axios request to update the database
                // we're using it on the page refresh and shopping cart update for local delivery
                // var that = this;

                // without this check I have an error in Console
                if (this.selectedQuoteID != '')
                {
                    axios.post('/save-selected-quote', {
                        _token:              this.csrf,
                        cart_id:             this.shoppingCartSummary.cart_id,
                        display_quote_price: this.display_quote_price,
                        quote_price:         this.quotes[this.selectedQuoteID]['cost'],
                        quote_label:         this.quotes[this.selectedQuoteID]['label'],
                        quote_company:       this.quotes[this.selectedQuoteID]['quote_company'],
                        quote_carrier:       this.quotes[this.selectedQuoteID]['carrier'],
                        quote_number:        this.quotes[this.selectedQuoteID]['number'],
                        quote_address:       this.quotes[this.selectedQuoteID]['address'],
                        quote_city:          this.quotes[this.selectedQuoteID]['city'],
                        quote_state:         this.quotes[this.selectedQuoteID]['state'],
                        quote_zip:           this.quotes[this.selectedQuoteID]['zip'],
                        quote_description:   this.quotes[this.selectedQuoteID]['description'],
                        tax_rate:            this.quotes[this.selectedQuoteID]['tax_rate'],
                        local_delivery_area: this.quotes[this.selectedQuoteID]['local_delivery_area'], // ['yes', 'no']
                        selected_quote:      this.selectedQuoteID,
                        quote_count:         this.shippingQuote.quote_count,
                        save_event:          'no',  // check this variable in Controller to generate events
                    })

                    .catch(function (error) {
                        console.log(error);
                    });
                }

            },

            saveSelectedQuoteAndQuoteEvent: function()
            {
                // Send axios request to update the database
                // Also save the shopping cart event and quote event
                // On the page refresh and shopping cart update use the function above
                // var that = this;

                // without this check I have an error in Console
                if (this.selectedQuoteID != '')
                {
                    axios.post('/save-selected-quote', {
                        _token:              this.csrf,
                        cart_id:             this.shoppingCartSummary.cart_id,
                        display_quote_price: this.display_quote_price,
                        quote_price:         this.quotes[this.selectedQuoteID]['cost'],
                        quote_label:         this.quotes[this.selectedQuoteID]['label'],
                        quote_company:       this.quotes[this.selectedQuoteID]['quote_company'],
                        quote_carrier:       this.quotes[this.selectedQuoteID]['carrier'],
                        quote_number:        this.quotes[this.selectedQuoteID]['number'],
                        quote_address:       this.quotes[this.selectedQuoteID]['address'],
                        quote_city:          this.quotes[this.selectedQuoteID]['city'],
                        quote_state:         this.quotes[this.selectedQuoteID]['state'],
                        quote_zip:           this.quotes[this.selectedQuoteID]['zip'],
                        quote_description:   this.quotes[this.selectedQuoteID]['description'],
                        tax_rate:            this.quotes[this.selectedQuoteID]['tax_rate'],
                        local_delivery_area: this.quotes[this.selectedQuoteID]['local_delivery_area'], // ['yes', 'no']
                        selected_quote:      this.selectedQuoteID,
                        quote_count:         this.shippingQuote.quote_count,
                        save_event:          'yes',  // check this variable in Controller to generate events
                    })

                    .catch(function (error) {
                        console.log(error);
                    });
                }

            },

            updateCheckoutProcess: function()
            {
                // Send axios request to update the DB
                // we're using it on the page refresh and shopping cart update
                // var that = this;
                axios.post('/update-checkout-process', {
                    quote_completed: 'yes',
                    _token: this.csrf,
                })

                .catch(function (error) {
                    console.log(error);
                });

            },

            showSpinnerModal: function()
            {
                this.$modal.show('spinner');
            },

            hideSpinnerModal: function()
            {
                this.$modal.hide('spinner');
            },


        } // end methods
    }

</script>

<style>

select:invalid,
select {
  color: #9CA3AF;
}

</style>