<template>
    <modal name="protection-policy" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 py-4 text-base text-gray-700">

            <div class="flex justify-end">
                <div class="px-2 py-1 bg-gray-700 hover:bg-blue-700 hover:cursor-pointer rounded-md" @click="closeThisModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>

            <div class="flex justify-center mb-6">
                <img src="/img/the-furniture-warehouse-logo.png" alt="The Furniture Warehouse Logo">
            </div>

            <div class="flex justify-center mb-6">
                <img src="/img/shield/we-have-got-you-covered.png" alt="We've Got You Covered Banner">
            </div>

            <p class="mb-6">
                <span class="text-lg font-semibold">
                    The Furniture Warehouse Accidental Protection Plan 
                </span>
                provides <span class="text-red-700 font-bold whitespace-nowrap">3 years</span> 
                of coverage against accidental stains and damages on furniture, including leather, 
                fabric, vinyl, and wood. It also protects against mechanical failures in Power, Frame, 
                and Mechanisms. Adjustable Beds provide 
                <span class="text-red-700 font-bold whitespace-nowrap">5 years</span> 
                of protection. Enjoy peace of mind, letting your furniture live with you worry-free.
            </p>

            <div class="flex justify-end">
                <img src="/img/shield-2/coverage-headers.webp" alt="Coverage for upholstery, wood, and adjustable beds." />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/accidental-stains.webp" alt="Coverage of Accidental Stains" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/heat-marks-and-burns.webp" alt="Coverage of Heat Marks and Burns" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/breakdown-or-breakage-of-frames.webp" alt="Breakdown or Breakage of Frames" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/rips-and-tears.webp" alt="Coverage of Rips, Tears, and Punches" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/breakage-of-motors.webp" alt="Coverage of Breakage of Motors" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/power-surges.webp" alt="Coverage of Power Surges" />
            </div>

            <div class="flex justify-end">
                <img src="/img/shield-2/controller.webp" alt="Coverage of Controllers" />
            </div>



            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/coverage-headers.webp" alt="Coverage for upholstery, wood, and adjustable beds." />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/cracking-bubbling-peeling-of-finish.webp" alt="Coverage of Checking, Cracking, Bubbling, and Peeling of Finish" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/lifting-of-veneers.webp" alt="Peeling of Finish or Lifting of Veneers" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/breakage-of-glass-or-mirrors.webp" alt="Coverage of Chipping and Breakage of Glass or Mirrors" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/scratches-that-penetrate-the-surface.webp" alt="Coverage of Scratches or Gouges that Penetrate the Surface" />
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield-2/liquid-marks-or-rings.webp" alt="Coverage of Liquid Marks or Rings" />
            </div>

            <div class="flex justify-end mb-10">
                <img src="/img/shield-2/adjustable-beds-5-year-coverage.webp" alt="Adjustable Beds 5 Year Coverage" />
            </div>

            

            <h3 class="mb-2 text-lg font-semibold"> 
                WITHOUT A CLAIM, YOU HAVE MORE TO GAIN!!
            </h3>

            <p class="mb-6">
                Congratulations on your new furniture and the decision to protect your new furniture with The
                Furniture Warehouse protection programs. We want you to enjoy your new furniture investment for 
                years to come without the fear of accidents or mechanical failures.
            </p>

            <p class="mb-4">
                The Furniture Warehouse will issue a 
                <span class=" text-red-700 font-semibold">
                    $100 bonus credit
                </span>
                if there are no claims processed through the life of the protection period. This $100 bonus credit 
                can be used on the purchase of $500 or more before tax and delivery and will be issued at the end
                of the protection program.
            </p>

            <p class="mb-4">
                When your protection program expires, your coupon can be redeemed up to 90 days from your 
                program expiration date.
            </p>

            <p class="mb-4 italic">
                ** Some exclusions apply. Accidental coverages are from a single incident. 
            </p>

            <p class="mb-6 text-center">
                <a href="/furniture-shield" class="text-blue-700 hover:underline whitespace-nowrap">
                    See the coverage details.
                </a>
            </p>

            
            <div class="flex justify-center mb-10">
                <div class="mx-auto px-4 py-2 bg-gray-700 rounded-full text-white tracking-wider uppercase hover:bg-blue-700 hover:cursor-pointer" @click="closeThisModal">
                    Close
                </div>
            </div>

        </div>

    </modal>
</template>



<script>

export default {

    methods: {

        closeThisModal: function()
        {
            this.$modal.hide('protection-policy');
        },

        openThisModal: function()
        {
            this.$modal.open('protection-policy');
        }
    }
}

</script>

