<template>
    <div class="w-full sm:w-1/2 mb-12 text-base text-gray-700">
        <div class="mx-2 lg:mx-10">

            <h2 class="text-3xl mt-2 mb-3 text-blue-700">
                Get Your Delivery Details
            </h2>
    
            <p class="mb-5">
                Please fill out the form below to get your delivery estimated time.
            </p>
    
            <form action="/delivery-details" method="POST" v-on:submit="onFormSubmit">
    
                <slot></slot>

                <div class="mb-3">
                    <label for="phone" class="form-label inline-block text-gray-500">
                        Phone
                    </label>
                    <input
                        type="tel"
                        name="phone"
                        v-model="phone"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="phone"
                        v-mask="'###-###-####'" 
                        placeholder="Example: XXX-XXX-XXXX"
                        required
                    />
                </div>
    
                <div class="mb-3">
                    <label for="invoice" class="form-label inline-block text-gray-500">
                        Invoice #
                    </label>
                    <input
                        type="text"
                        name="invoice"
                        v-model="invoice"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="invoice"
                        required
                    />
                </div>

                <div class="mb-3" style="display: none">
                    <label for="user-name" class="form-label inline-block text-gray-500">
                        User Name
                    </label>
                    <input
                        type="text"
                        name="user_name"
                        value=""
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="user-name"
                        autocomplete="off"
                    />
                </div>
    
    
                <div class="mb-8">
                    <label for="zip_code" class="form-label inline-block text-gray-500">
                        Delivery Zip Code
                    </label>
                    <input
                        type="text"
                        name="zip_code"
                        v-model="zip_code"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="zip_code"
                        required
                    />
                </div>

                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>

                <div v-if="! displaySpinner">
                    <button role="submit" v-if="! disableButton" class="inline-block px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                        Find
                    </button>
                    
                    <div v-if="disableButton" class="inline-block px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Fill Out The Form
                    </div>
                </div>
            </form>
        </div>

    </div> 

</template>

<script>


export default {
    props: [
        'old_phone',
        'old_invoice',
        'old_zip_code',
    ],

    data: function () {

        return {
            phone: this.old_phone,
            invoice: this.old_invoice,
            zip_code: this.old_zip_code,
            watch_phone: false,
            watch_invoice: false,
            watch_zip_code: false,
            disableButton: true,
            displaySpinner: false,
        }
    },

    mounted: function () {
        if(this.phone.length > 11)
        {
            this.watch_phone = true;
        }

        if(this.invoice.length > 5)
        {
            this.watch_invoice = true;
        }

        if(this.zip_code.length > 4)
        {
            this.watch_zip_code = true;
        }
    },

    watch:{

        phone: function(val, oldVal)
        {
            if (this.phone.length > 11)
            {
                this.watch_phone = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_phone = false;
            }
        },

        invoice: function(val, oldVal)
        {
            if (this.invoice.length > 5)
            {
                this.watch_invoice = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_invoice = false;
            }
        },

        zip_code: function(val, oldVal)
        {
            if (this.zip_code.length > 4)
            {
                this.watch_zip_code = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_zip_code = false;
            }
        },
    },

    methods:{

        checkFormVariables ()
        {
            if(this.watch_phone == true && this.watch_invoice == true && this.watch_zip_code == true)
            {
                this.disableButton = false;
            }
        },

        onFormSubmit ()
        {
            this.displaySpinner = true;
        },

    }
}

</script>
